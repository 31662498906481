import React, { useEffect } from "react";
import { server } from "../service/api";
import logo from "../assets/Logo.svg";
import { useAuth } from "../context/AuthContext";
import { login, logout } from "../service/auth";

const SpinnerComponent = () => {
	const { setAuthenticated, setUser } = useAuth();
	// ====================VERIFY IF USER IS AUTHENTICATED================
	useEffect(() => {
		load();
	});

	async function load() {
		try {
			await server.get("/sanctum/csrf-cookie");
			const response = await server.get("/api/auth/profile");
			setUser(response.data.user);
			login(response.data.access_token);
			setAuthenticated(true);
		} catch (error) {
			logout();
			setAuthenticated(false);
		}
	}

	return (
		<div className="fallback-spinner h-100">
			<img className="fallback-logo" src={logo} alt="logo" />
			<div className="loading">
				<div className="effect-1 effects"></div>
				<div className="effect-2 effects"></div>
				<div className="effect-3 effects"></div>
			</div>
		</div>
	);
};

export { SpinnerComponent };
