import axios from "axios";
import { getToken } from "./auth";

//const baseURL = "http://localhost:8000/";
const baseURL = "http://server.insignio.tech";

// const baseURL = "http://localhost/brokers_tech_laravel/public";

const api = axios.create({
	baseURL,
	withCredentials: true,
});

api.interceptors.request.use(async (config) => {
	const token = getToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

export { api as server };
