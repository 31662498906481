import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { SpinnerComponent } from "./components/Spinner";
import AuthProvider from "./context/AuthContext";
import { BreadcrumbProvider } from "./context/BreadcrumbContext";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<BreadcrumbProvider>
				<Suspense fallback={<SpinnerComponent />}>
					<LazyApp />
				</Suspense>
			</BreadcrumbProvider>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
