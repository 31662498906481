import React, { createContext, useContext, useState } from "react";

const BreadcrumbContext = createContext();

export function BreadcrumbProvider({ children }) {
  const [_Breadcrumb, setBreadcrumb] = useState();

  return (
    <BreadcrumbContext.Provider value={{ _Breadcrumb, setBreadcrumb }}>
      {children}
    </BreadcrumbContext.Provider>
  );
}

export function useBreadcrumb() {
  const { _Breadcrumb, setBreadcrumb } = useContext(BreadcrumbContext);
  return { _Breadcrumb, setBreadcrumb };
}
